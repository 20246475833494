@import "styles/main.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $space-4 7.8rem;
  // border-radius: $radius-sm1;
  width: max-content;
  min-width: 20rem;
  cursor: pointer;
  transition: 0.3s background-color;
  background-color: $brand;
  height: 6rem;
  gap: $space-2;
  white-space: nowrap;
  min-width: 100%;
  border: none;
  outline: none;
  color: $text;
  font-weight: bold;
  font-size: 1.6rem;

  @include responsive(small) {
    height: 8rem;
    font-size: 2.4rem;
    min-width: 33rem;
  }

  &:hover,
  &:active {
    background-color: $brand-hover;
  }
}

.link-disable {
  pointer-events: none;
  opacity: 0.4;
  cursor: default;
}
