@import "styles/main.scss";

.table-icon {
  width: 100%;

  .table-head {
    border: 1px solid $border;
    padding: $space-6;
    font-size: 2rem;
    border-bottom: none;

    .content-table-head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: rgba(#fff, 80%);
      font-size: 1.6rem;

      @include responsive(tablet) {
        font-size: 2rem;
      }

      &--half {
        // @include responsive(tablet-2) {
        //   width: calc(1200px - 60vw);
        // }

        @include responsive(small) {
          width: calc(1200px - 43rem);
        }
      }

      .see-more {
        display: flex;
        align-items: center;
        color: $brand;
        cursor: pointer;
        transition: 0.3s all;
        font-size: 1.6rem;

        &:hover {
          color: $brand-hover;
        }
      }
    }
  }

  .table-body {
    // border: 1px solid $border;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border: 1px solid $border;

    @include responsive(tablet) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include responsive(small) {
      grid-template-columns: repeat(4, 1fr);
    }

    .item-icon {
      width: 100%;
      aspect-ratio: 1/1;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $border;
      // border-top: 1px solid $border;
      position: relative;
      z-index: 0;
      transition: 0.3s all;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: -1;
        transition: 0.3s all;
        opacity: 0.3;
      }

      &:hover {
        border-color: $brand-hover;
        // border-left-color: $brand-hover;

        &::after {
          background-color: $brand-hover;
        }
      }

      // &--border-right {
      //   border-right: 1px solid $border;
      // }

      &--empty-item {
        cursor: default;

        &:hover {
          border-color: $border;

          &::after {
            background-color: unset;
          }
        }
      }

      .icon-logo {
        width: 9rem;

        @include responsive(tablet) {
          width: 12rem;
        }

        @include responsive(small) {
          width: 16rem;
        }
      }
    }
  }
}
