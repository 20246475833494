@import "styles/main.scss";

.header-wrapper {
  width: 100%;
  padding: $space-5 0;
  position: fixed;
  top: -15rem;
  left: 0;
  z-index: 999;
  transition: 1s top;
  // background-color: $background;

  @include responsive(small) {
    padding: $space-8 0;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, rgba(#080808, 0) 0%, rgba(#080808, 0.8) 20%, #080808 50%);
    z-index: -1;
  }

  &--show-head {
    top: 0;
  }

  &--transparent {
    &::after {
      display: none;
    }
  }

  &--about {
    // &::after {
    //   height: 150%;
    // }
  }

  &--portfolio {
    &::after {
      background: #080808;
    }
  }

  .header-container {
    @include container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $space-2;

    @include responsive(small) {
      padding: 0 !important;
    }

    .logo {
      width: 13rem;
    }

    a {
      display: flex;
      align-items: center;
    }

    .icon-menu {
      cursor: pointer;
      transition: 0.5s color;
      position: relative;
      z-index: 999;
      // font-size: 2.4rem;

      // @include responsive(small) {
      font-size: 3.6rem;
      // }

      &:hover {
        color: $brand-hover;
      }

      &--close {
        color: $text-inverse;
        font-size: 3rem;
      }
    }
  }

  .menu-bar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background-color: $white;
    z-index: 998;
    transition: 0.3s all;
    color: $text-inverse !important;

    @include responsive(small) {
      right: -50%;
      width: 30%;
    }

    @include responsive(mac-16) {
      width: 35%;
    }

    @include responsive(screen2k) {
      width: 40%;
    }

    &--show-menu {
      right: 0;
    }

    .menu-head {
      padding: $space-4 $space-6;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: $space-6;

      @include responsive(small) {
        width: 47rem;
        margin-bottom: $space-52;
      }

      .icon-close {
        cursor: pointer;
        transition: 0.3s all;
        // font-size: 2.4rem;

        // @include responsive(small) {
        font-size: 3.6rem;
        // }

        &:hover {
          color: $brand-hover;
        }
      }
    }

    .menu-list,
    .menu-social {
      padding: 0 $space-4;
      display: flex;
      flex-direction: column;
      margin-bottom: $space-5 * 2;
      position: relative;

      @include responsive(small) {
        padding: 0 $space-12;
        margin-bottom: $space-12;
      }

      @include responsive(mac-16) {
        margin-bottom: $space-10 * 2;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -$space-5;
        left: $space-4;
        height: 1px;
        width: calc(100% - $space-4 * 2);
        background-color: $border-inverse;

        @include responsive(small) {
          left: $space-12;
          bottom: -$space-5;
          width: calc(100% - $space-12 * 2);
        }

        @include responsive(mac-16) {
          bottom: -$space-10;
        }
      }

      .link-menu {
        color: $text-inverse;
        cursor: pointer;
        transition: 0.3s all;
        margin-bottom: $space-2;
        font-size: 2.4rem;
        width: max-content;
        display: flex;
        align-items: flex-end;
        gap: $space-2;

        @include responsive(small) {
          font-size: 3.2rem;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          color: $brand-hover;
        }

        &--social {
          font-size: 2rem;

          @include responsive(small) {
            font-size: 2.4rem;
          }
        }

        &--disable {
          pointer-events: none;

          &:hover {
            color: unset;
          }
        }

        &--active {
          color: $brand;
        }

        .box-active {
          width: 8px;
          height: 8px;
          background-color: $brand;
          margin-bottom: 0.8rem;

          @include responsive(small) {
            margin-bottom: 1.2rem;
          }
        }

        .coming-soon {
          font-size: 1.6rem;
          margin-bottom: 6px;

          &--social {
            margin-bottom: 0;

            @include responsive(small) {
              margin-bottom: 2px;
            }
          }
        }
      }
    }

    .menu-social {
      &::after {
        display: none;
      }
    }

    .box-button {
      width: 100%;
      padding: 0 $space-6;
      position: absolute;
      bottom: 18%;
      left: 50%;
      transform: translateX(-50%);

      @include responsive(small) {
        padding: 0 $space-12;
        bottom: 6.4rem;
      }

      &::after {
        content: "";
        position: absolute;
        top: -$space-5;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: $border-inverse;

        @include responsive(small) {
          top: -$space-8;
        }
      }

      .button-menu {
        width: 35rem;

        button {
          width: 35rem;
        }
      }
    }
  }
}
