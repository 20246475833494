// $text: #231f20;

// $black: #1b1b1b;
// $grey: #686868;
// $grey1: #8f8f8f;
// $grey2: #a4a4a4;
// $grey3: #c0c0c0;
// $white: #ffffff;
// $red: #ff5d60;
// $green: #5fff90;
// $purple: #c1a9fd;
// $blue: #00cec2;
// $orange: #ffb96c;
// $yellow-secondary: #ffe675;
// $yellow-hover: #e59b00;
// $yellow: #fba900;

// arche
$brand: #f16122;
$brand-hover: #fe704c;
$border-inverse: #cccccc;
$border: #4b4b4b;
$text: #f8f8f8;
$text-secondary: #979797;
$text-inverse: #404040;
$text-inverse-secondary: #1c1c1c;
$background-inverse: #eeeeee;
$background: #080808;
$background-hover: #33251e;
$background-2: #141414;
$background-2-hover: #573d32;
$background-3: #111111;
$white: #ffffff;
