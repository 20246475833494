// #fp-nav ul li:hover a.active span,
// #fp-nav ul li a.active span,
// .fp-slidesNav ul li:hover a.active span,
// .fp-slidesNav ul li a.active span {
//   background-color: $brand;
// }

#fp-nav {
  ul {
    li {
      cursor: pointer;

      &:hover {
        a {
          span {
            background-color: $brand-hover !important;
          }
        }
      }

      a {
        span {
          background-color: $brand !important;
        }
      }
    }
  }
}

.fp-watermark {
  display: none;
}

// .fp-overflow {
//   overflow-y: hidden;
// }
