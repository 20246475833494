@import "styles/main.scss";

.portfolioWrapper {
  @include container;
  height: 100%;

  :global {
    .portfolio-container {
      margin: 10rem 0 !important;

      @include responsive(small) {
        margin: 15rem 0 10rem !important;
      }

      h1 {
        span {
          color: $brand;
        }
      }

      .text-description {
        color: $text-secondary;
        font-size: 2.4rem;
        margin-bottom: $space-16;

        @include responsive(tablet) {
          width: 65rem;
        }
      }
    }
  }
}
