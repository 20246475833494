@import "styles/main.scss";

.landingBody {
  overflow: hidden;

  @include responsive(normal) {
    position: relative;
    width: 100%;
    height: 200vh !important;
  }

  :global {
    .landing-container {
      position: relative;
      // z-index: 0;
    }

    .nav-container {
      @include container;
      position: relative;
      display: none;
      z-index: 100;

      @include responsive(small) {
        display: block;
      }

      .list-nav {
        position: fixed;
        top: 15rem;
        left: 0;
        z-index: 5;
        width: 100%;

        .item-nav {
          @include container;
        }

        .nav-item {
          width: 20rem;
          display: flex;
          align-items: center;
          gap: $space-2;
          cursor: pointer;
          margin-bottom: $space-8;

          &--active {
            .box-nav {
              background-color: $brand;
              border-color: $brand !important;
            }

            &:hover {
              .box-nav {
                border-color: $brand !important;
              }

              .div-hover {
                background-color: $brand !important;
              }
            }
          }

          .box-nav {
            width: 1.6rem;
            height: 1.6rem;
            border: 2px solid $border;
            transition: 0.3s all;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &:hover {
            .box-nav {
              border-color: $brand-hover;
            }

            .div-hover {
              background-color: $brand-hover;
            }

            .nav-title {
              opacity: 1 !important;
            }
          }

          .div-hover {
            width: 0.6rem;
            height: 0.6rem;
            transition: 0.3s all;
          }

          .nav-title {
            font-size: 1.6rem;
            color: $text-secondary;
            line-height: 1;
            opacity: 0;
            transition: 0.3s all;
          }
        }
      }
    }

    .desktop-screen {
      display: none;

      @include responsive(normal) {
        display: block;
      }

      .section {
        width: 100%;
        height: 100vh !important;
      }

      .section-scroll {
        overflow: hidden;
        position: fixed;
        top: 0rem;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        opacity: 0;
        transition: 0.5s all;
        pointer-events: none;

        &--section-1 {
          background-color: #010101;
          // z-index: 1;
        }

        &--section-2 {
          // z-index: 2;
        }

        &--section-3 {
          // z-index: 3;
        }

        &--show {
          opacity: 1;
          pointer-events: unset;
        }

        .content-section-1,
        .content-section-2,
        .content-section-3 {
          @include container;
          height: calc(100vh - 12rem);
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          justify-content: flex-end;
          overflow: hidden;
          position: relative;
          z-index: 0;

          @include responsive(tablet) {
            padding: $space-16 $space-6 !important;
            padding-bottom: 0 !important;
          }

          @include responsive(small) {
            height: 100vh;
            padding: $space-16 0 !important;
            flex-direction: row;
            justify-content: space-between;
          }

          .section-2-wrapper {
          }

          .text-content {
            display: flex;
            // align-items: center;
            flex-direction: column;
            justify-content: flex-end;

            @include responsive(small) {
              max-width: 80rem;
            }

            &--section-2 {
              @include containerFix;
              width: 100%;
              justify-content: center;
              padding-top: 8rem;
              gap: $space-4;
              padding-left: 0 !important;
              padding-right: 0 !important;

              @include responsive(small) {
                justify-content: flex-start;
                padding-top: 3rem;
              }

              .text-section-2 {
                @include responsive(small) {
                  max-width: 80rem;
                }
              }
            }

            .text-title {
              margin-bottom: $space-4;
              transform: translateY(20rem);
              opacity: 0;
              transition: 0.5s all;

              @include responsive(tablet) {
                margin-bottom: 0;
              }

              &--section-1 {
                @include responsive(tablet) {
                  margin-bottom: $space-12;
                }

                @include responsive(small) {
                  margin-bottom: -2rem;
                }
              }

              &--show {
                transform: translateY(0rem);
                opacity: 1;
              }

              &--hide {
                transform: translateY(20rem) !important;
                opacity: 0;
              }

              span {
                color: $brand;
              }
            }

            .text-description {
              color: $text-secondary;
              font-size: 2.4rem;
              margin-bottom: $space-8;

              @include responsive(small) {
                font-size: 3.2rem;
                margin-bottom: 0;
                width: 80%;
                transform: translateY(20rem);
                opacity: 0;
                transition: 0.5s all;
              }

              &--section-2 {
                margin-bottom: 0;

                @include responsive(small) {
                  margin-bottom: unset;
                  width: 60%;
                }
              }

              &--show {
                transform: translateY(0rem);
                opacity: 1;
              }
            }
          }

          .text-container {
            @include responsive(small) {
              @include container;
            }

            &--section-3 {
              width: 100%;
              @include container;
              padding-top: 8rem;

              @include responsive(tablet) {
                padding-top: 8rem;
              }

              @include responsive(tablet) {
                margin-bottom: -1.5rem;
              }
            }
          }

          // .img-chess
          .video-box {
            position: absolute;
            right: 0;
            z-index: -1;
            width: 100%;
            bottom: -30%;
            height: 100rem;

            &::after {
              content: "";
              position: absolute;
              width: 40%;
              height: 20%;
              background: red;
              z-index: 5;
              bottom: 10%;
              right: 0rem;
              background: linear-gradient(0deg, #010101 50%, rgba(#010101, 0) 100%);
              z-index: 0;
              display: none;

              @include responsive(tablet) {
                width: 45%;
                bottom: 0;
                height: 20%;
              }

              @include responsive(mac-16) {
                bottom: -5rem;
              }
            }

            &--bg-blur {
              &::after {
                display: block;
              }
            }

            @include responsive(tablet) {
              bottom: -3rem;
              right: 0%;
              transform: unset;
            }

            @include responsive(small) {
              bottom: -10rem;
            }

            @include responsive(mac-16) {
              bottom: 0;
            }

            .video-content {
              width: calc(100% + 2.4rem);
              height: calc(100% + 2.4rem);
              position: absolute;
              bottom: 0;
              left: 0;
              transition: 0.5s all;

              &--stand {
                display: block;
              }

              &--hide-video {
                // display: none;
                opacity: 0;
              }

              @include responsive(tablet) {
              }
            }
          }

          .logo-container {
            position: absolute;
            top: 40vh;
            left: 0;
            z-index: 1;
            width: 100%;
            padding: 0 $space-4 !important;
            height: 50vh;
            overflow: hidden;

            @include responsive(tablet) {
              height: 54vh;
              //   width: unset;
              //   left: 0;
              //   min-width: 1200px !important;
            }

            @include responsive(small) {
              padding: 0 $space-6 !important;
              height: unset;
              top: 22%;
              left: unset;
              width: unset;
              min-width: 1200px !important;
              right: -25%;
            }

            @include responsive(mac-16) {
              top: 35%;
              right: -20%;
            }

            @include responsive(screen2k) {
              right: 0;
            }
          }

          .list-wrapper {
            width: 100%;
            overflow: auto;
            position: absolute;
            bottom: $space-4;
            left: 0;

            @include responsive(small) {
              bottom: 6.4rem;
            }

            &::-webkit-scrollbar {
              display: none;
            }

            .list-card {
              display: flex;
              gap: $space-4;
              bottom: $space-12;
              right: 0;
              padding: 0 1.6rem;

              &--small {
                @include container;
                // justify-content: flex-sta;
              }

              @include responsive(tablet) {
                min-height: 45rem;
              }

              @include responsive(small) {
                gap: $space-6;
                min-height: 55rem;
                max-height: 60rem;
                padding-left: 55%;
              }

              .item-card {
                // width: 40rem;
                background-color: $background-2;
                cursor: pointer;
                transition: 0.1s all;
                border: 1px solid transparent;
                overflow: hidden;
                min-width: 30rem;
                max-width: 30rem;
                position: relative;
                z-index: 0;

                &:last-child {
                }

                &::after {
                  content: "";
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;
                  z-index: -1;
                  background-color: transparent;
                  transition: 0.1s all;
                }

                &--see-more {
                  overflow: hidden;

                  @include responsive(small) {
                    &:hover {
                      .content-see-more {
                        margin-top: -3rem;
                      }
                    }
                  }

                  &--empty {
                    min-width: 5px !important;
                  }

                  .content-see-more {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    font-size: 1.8rem;
                    gap: $space-4;
                    transition: 0.1s all;
                    width: 100%;
                    height: 100%;

                    .icon-more {
                      font-size: 2.4rem;
                      color: $brand;
                    }
                  }
                }

                &--empty {
                  min-width: 5px !important;
                  background-color: transparent;

                  @include responsive(tablet) {
                    min-width: 5px !important;
                  }
                }

                @include responsive(tablet) {
                  min-width: 40rem;
                  max-width: 40rem;
                }

                @include responsive(small) {
                  min-width: 40rem;
                  max-width: 40rem;

                  &:hover {
                    border-color: $brand-hover;

                    &::after {
                      background-color: rgba($brand-hover, 0.1);
                    }

                    .img-card {
                      height: calc(100% - 5rem) !important;
                    }

                    .text-card {
                      transform: translateY(-5rem);
                    }
                  }
                }

                .box-img {
                  width: 100%;
                  overflow: hidden;
                  aspect-ratio: 1 / 1;

                  .img-card {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: 0.1s all;
                  }
                }
              }

              .text-card {
                padding: $space-4;
                width: 100%;
                transition: 0.1s all;

                @include responsive(small) {
                  padding: $space-8;
                }

                .text-title {
                  margin-bottom: $space-4;
                  display: block;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .text-description-card {
                  color: $text-secondary;

                  display: block;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  @include responsive(tablet) {
                    -webkit-line-clamp: 3;
                  }
                }
              }
            }
          }

          .btn-pitch-us {
            width: 100%;
            margin-bottom: 10rem;

            @include responsive(tablet) {
              width: unset;
              margin-right: auto;
            }

            @include responsive(small) {
              margin-bottom: 0;
              margin-right: unset;
            }
          }
        }

        .content-section-1 {
        }

        .content-section-3 {
          // overflow: auto;
          max-width: 100%;
          width: 100%;
          padding: 0;
          padding-bottom: 10rem;
          justify-content: space-between;
          // height: calc(100vh - 2rem);

          @include responsive(tablet) {
            // height: calc(100vh - 10rem);
          }

          @include responsive(small) {
            height: 100vh;
            padding-bottom: 0;
            justify-content: unset;
            padding: unset;
          }
        }

        .content-section-2 {
          max-width: 100%;
          width: 100%;
          overflow: hidden;
          justify-content: flex-start;
          // height: calc(100vh - 12rem);
          // height: 100vh;

          @include responsive(tablet) {
            height: calc(100vh - 12rem);
          }

          @include responsive(small) {
            height: 100vh;
            padding-top: 0;
            justify-content: unset;
          }
        }
      }
    }

    .mobile-screen {
      // height: 100vh;

      @include responsive(normal) {
        display: none;
      }

      .section {
        overflow: hidden;

        &--section-1 {
          background-color: #010101;
        }

        .content-section-1,
        .content-section-2,
        .content-section-3 {
          @include container;
          height: 100svh;
          // height: calc(100vh - 12rem);
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          justify-content: flex-end;
          overflow: hidden;
          position: relative;
          z-index: 0;

          @include responsive(tablet) {
            padding: $space-16 $space-6 !important;
            padding-bottom: 0 !important;
          }

          @include responsive(small) {
            height: 100vh;
            padding: $space-16 0 !important;
            flex-direction: row;
            justify-content: space-between;
          }

          .section-2-wrapper {
          }

          .text-content {
            display: flex;
            // align-items: center;
            flex-direction: column;
            justify-content: flex-end;

            @include responsive(small) {
              max-width: 80rem;
            }

            &--section-2 {
              @include containerFix;
              width: 100%;
              justify-content: center;
              padding-top: 8rem;
              gap: $space-4;
              padding-left: 0 !important;
              padding-right: 0 !important;

              @include responsive(small) {
                justify-content: flex-start;
                padding-top: 3rem;
              }

              .text-section-2 {
                @include responsive(small) {
                  max-width: 80rem;
                }
              }
            }

            .text-title {
              margin-bottom: $space-4;

              @include responsive(tablet) {
                margin-bottom: 0;
              }

              &--section-1 {
                @include responsive(tablet) {
                  margin-bottom: $space-12;
                }

                @include responsive(small) {
                  margin-bottom: -2rem;
                }
              }

              span {
                color: $brand;
              }
            }

            .text-description {
              color: $text-secondary;
              font-size: 2.4rem;
              margin-bottom: $space-8;

              @include responsive(small) {
                font-size: 3.2rem;
                margin-bottom: 0;
                width: 80%;
              }

              &--section-2 {
                margin-bottom: 0;

                @include responsive(small) {
                  margin-bottom: unset;
                  width: 60%;
                }
              }
            }
          }

          .text-container {
            @include responsive(small) {
              @include container;
            }

            &--section-3 {
              width: 100%;
              @include container;
              padding-top: 8rem;

              @include responsive(tablet) {
                padding-top: 8rem;
              }

              @include responsive(tablet) {
                margin-bottom: -1.5rem;
              }
            }
          }

          // .img-chess
          .video-box {
            position: absolute;
            right: 0;
            z-index: -1;
            width: 100%;
            bottom: -30%;
            height: 100rem;

            &::after {
              content: "";
              position: absolute;
              width: 40%;
              height: 20%;
              background: red;
              z-index: 5;
              bottom: 10%;
              right: 0rem;
              background: linear-gradient(0deg, #010101 50%, rgba(#010101, 0) 100%);
              z-index: 0;
              display: none;

              @include responsive(tablet) {
                width: 45%;
                bottom: 0;
                height: 20%;
              }

              @include responsive(mac-16) {
                bottom: -5rem;
              }
            }

            &--bg-blur {
              &::after {
                display: block;
              }
            }

            @include responsive(tablet) {
              bottom: -3rem;
              right: 0%;
              transform: unset;
            }

            @include responsive(small) {
              bottom: -10rem;
            }

            @include responsive(mac-16) {
              bottom: 0;
            }

            .video-content {
              width: calc(100% + 2.4rem);
              height: calc(100% + 2.4rem);
              position: absolute;
              bottom: 0;
              left: 0;
              transition: 0.5s all;

              &--stand {
                display: block;
              }

              &--hide-video {
                // display: none;
                opacity: 0;
              }

              @include responsive(tablet) {
              }
            }
          }

          .logo-container {
            position: absolute;
            top: 40vh;
            left: 0;
            z-index: 1;
            width: 100%;
            padding: 0 $space-4 !important;
            height: 50vh;
            overflow: hidden;

            @include responsive(tablet) {
              height: 54vh;
              //   width: unset;
              //   left: 0;
              //   min-width: 1200px !important;
            }

            @include responsive(small) {
              padding: 0 $space-6 !important;
              height: unset;
              top: 22%;
              left: unset;
              width: unset;
              min-width: 1200px !important;
              right: -25%;
            }

            @include responsive(mac-16) {
              top: 35%;
              right: -20%;
            }

            @include responsive(screen2k) {
              right: 0;
            }
          }

          .list-wrapper {
            width: 100%;
            overflow: auto;
            position: absolute;
            bottom: $space-4;
            left: 0;

            @include responsive(small) {
              bottom: 6.4rem;
            }

            &::-webkit-scrollbar {
              display: none;
            }

            .list-card {
              display: flex;
              gap: $space-4;
              bottom: $space-12;
              right: 0;
              padding: 0 1.6rem;

              &--small {
                @include container;
                // justify-content: flex-sta;
              }

              @include responsive(tablet) {
                min-height: 45rem;
              }

              @include responsive(small) {
                gap: $space-6;
                min-height: 55rem;
                max-height: 60rem;
                padding-left: 55%;
              }

              .item-card {
                // width: 40rem;
                background-color: $background-2;
                cursor: pointer;
                transition: 0.1s all;
                border: 1px solid transparent;
                overflow: hidden;
                min-width: 30rem;
                max-width: 30rem;

                &:last-child {
                }

                &--see-more {
                  overflow: hidden;

                  @include responsive(small) {
                    &:hover {
                      .content-see-more {
                        margin-top: -3rem;
                      }
                    }
                  }

                  &--empty {
                    min-width: 5px !important;
                  }

                  .content-see-more {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    font-size: 1.8rem;
                    gap: $space-4;
                    transition: 0.1s all;
                    width: 100%;
                    height: 100%;

                    .icon-more {
                      font-size: 2.4rem;
                      color: $brand;
                    }
                  }
                }

                &--empty {
                  min-width: 5px !important;
                  background-color: transparent;

                  @include responsive(tablet) {
                    min-width: 5px !important;
                  }
                }

                @include responsive(tablet) {
                  min-width: 40rem;
                  max-width: 40rem;
                }

                @include responsive(small) {
                  min-width: 40rem;
                  max-width: 40rem;
                  &:hover {
                    background-color: rgba($brand-hover, 0.1);
                    border-color: $brand-hover;

                    .img-card,
                    .text-card {
                      transform: translateY(-5rem);
                    }
                  }
                }

                .box-img {
                  width: 100%;
                  overflow: hidden;
                  aspect-ratio: 1 / 1;

                  .img-card {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: 0.1s all;
                  }
                }
              }

              .text-card {
                padding: $space-4;
                width: 100%;
                transition: 0.1s all;

                @include responsive(small) {
                  padding: $space-8;
                }

                .text-title {
                  margin-bottom: $space-4;
                  display: block;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .text-description-card {
                  color: $text-secondary;

                  display: block;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  @include responsive(tablet) {
                    -webkit-line-clamp: 3;
                  }
                }
              }
            }
          }

          .btn-pitch-us {
            width: 100%;
            margin-bottom: 10rem;

            @include responsive(tablet) {
              width: unset;
              margin-right: auto;
            }

            @include responsive(small) {
              margin-bottom: 0;
              margin-right: unset;
            }
          }
        }

        .content-section-1 {
        }

        .content-section-3 {
          // overflow: auto;
          max-width: 100%;
          width: 100%;
          padding: 0;
          padding-bottom: 10rem;
          justify-content: space-between;
          // height: calc(100vh - 2rem);

          @include responsive(tablet) {
            // height: calc(100vh - 10rem);
          }

          @include responsive(small) {
            height: 100vh;
            padding-bottom: 0;
            justify-content: unset;
            padding: unset;
          }
        }

        .content-section-2 {
          max-width: 100%;
          width: 100%;
          overflow: hidden;
          justify-content: flex-start;
          // height: calc(100vh - 12rem);
          // height: 100vh;

          @include responsive(tablet) {
            height: calc(100vh - 12rem);
          }

          @include responsive(small) {
            height: 100vh;
            padding-top: 0;
            justify-content: unset;
          }
        }
      }
    }

    .scroll-box {
      position: absolute;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: $space-4;
      padding: $space-6;
      width: 100%;
      margin-top: $space-6;
      font-size: 1.6rem;
      bottom: -1rem;
      left: 0;
      // background: linear-gradient(0deg, #010101 50%, rgba(#010101, 0) 100%);

      @include responsive(tablet) {
        bottom: 0;
      }

      @include responsive(small) {
        margin-top: 0;
        display: none;
      }

      &--section-1 {
        // bottom: -$space-6;

        @include responsive(tablet) {
          bottom: -$space-8;
        }
      }

      &--section-2 {
        padding: $space-8 0 $space-4;
        width: 100%;
        z-index: 1;
        // padding: 0;
        bottom: 0;
        background: linear-gradient(
          180deg,
          rgba($background, 0) 0%,
          rgba($background, 0.4) 35%,
          rgba($background, 0.8) 45%,
          $background 100%
        );
      }

      .icon-down {
        font-size: 2.4rem;
      }
    }
  }
}
