@font-face {
  font-family: "Sora";
  src: url("../assets/fonts/Sora-Light.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Sora";
  src: url("../assets/fonts/Sora-SemiBold.ttf");
  font-weight: bold;
}
