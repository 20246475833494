@import "styles/main.scss";

.aboutWrapper {
  :global {
    .section-container {
      position: relative;
      z-index: 0;

      &--background {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100svh;
        // height: calc(100vh - 12rem);
        background-color: $brand;
        overflow: hidden;

        @include responsive(tablet) {
          height: 100vh;
        }
      }
    }

    .section-3 {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 5rem;
        left: 0;
        width: 100%;
        height: 12rem;
        background: linear-gradient(0deg, rgba($background, 0) 0%, rgba($background, 0.8) 20%, $background 50%);
        z-index: 1;
      }
    }

    .scroll-box {
      position: absolute;
      bottom: 2.4rem;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: $space-4;
      z-index: 0;
      width: 100%;
      font-size: 1.6rem;
      cursor: pointer;

      @include responsive(tablet-2) {
        bottom: 4.8rem;
      }

      @include responsive(small) {
        bottom: 3.2rem;
      }

      &--section-3 {
        &::after {
          content: "";
          position: absolute;
          bottom: -3.2rem;
          left: 0;
          width: 100%;
          height: 15rem;
          background: linear-gradient(180deg, rgba($background, 0) 0%, rgba($background, 0.4) 20%, $background 50%);
          z-index: -1;

          @include responsive(small) {
            height: 15rem;
          }

          @include responsive(mac-16) {
            height: 20rem;
          }
        }
      }

      .icon-down {
        font-size: 2.4rem;
      }

      .icon-up {
        font-size: 2.4rem;
        transform: rotate(180deg);
      }
    }

    .section-content {
      @include container;
      text-align: center;

      &--body {
        display: flex;
        align-items: flex-end;
        height: 100vh;
        padding-bottom: 5rem;
      }

      &--section-3 {
      }

      .content-text {
        width: max-content;
        margin: 0 auto;
        padding: 0;
        color: $text-secondary;

        @include responsive(tablet) {
          padding: 0 $space-16;
        }

        .text {
          overflow: hidden; /* Ensures the content is not revealed until the animation */
          border-right: 0.15em solid orange; /* The typwriter cursor */
          white-space: nowrap; /* Keeps the content on a single line */
          margin: 0 auto; /* Gives that scrolling effect as the typing happens */
          animation:
            typing 2s steps(30, end),
            blink-caret 1s step-end infinite;
          color: $text-inverse-secondary;

          span {
            color: $text;
          }
        }
      }
    }

    .section-content-2 {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding-top: 5rem;

      @include responsive(tablet) {
        padding-top: 0;
      }

      .icon-sound-wrapper {
        position: relative;
        width: 4.3rem;
        height: 4.3rem;
        font-size: 5.9rem;
        margin-bottom: $space-10;
        cursor: pointer;

        &--disable {
          pointer-events: none;
        }

        .icon-sound {
          width: 100%;
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          &--show {
            opacity: 1;
          }
        }

        .icon-sound-span {
          transition: 0.3s color;
          color: $text-secondary;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          @include responsive(small) {
            &:hover {
              color: $text;
            }
          }

          &--hide {
            display: none;
          }
        }
      }

      h1 {
        color: $text-secondary;

        @include responsive(tablet-2) {
          width: 80%;
        }

        span {
          color: $text;
        }
      }
    }

    .section-content-3 {
      height: 50vh;
      font-size: 3.2rem;

      @include responsive(tablet-2) {
        font-size: 4.8rem;
      }

      .content-text {
        width: 100%;
        padding-bottom: 15rem;
      }
    }

    .section-content-4 {
      // padding-top: 5rem;

      @include responsive(tablet) {
        padding-top: 0;
      }

      .section-title {
        margin-bottom: $space-3;
      }

      .text-description {
        font-size: 1.8rem;
        color: $text-secondary;
        position: relative;

        @include responsive(tablet) {
          font-size: 2.4rem;
        }

        &::after {
          content: "";
          position: absolute;
          bottom: -7rem;
          left: 50%;
          transform: translateX(-50%);
          height: 5rem;
          width: 1px;
          background-color: $brand;
          display: none;

          @include responsive(tablet) {
            display: block;
          }
        }

        &::before {
          content: "";
          position: absolute;
          bottom: -7rem;
          left: 50%;
          transform: translateX(-50%);
          height: 1px;
          width: 66.7%;
          background-color: $brand;
          display: none;

          @include responsive(tablet) {
            display: block;
          }
        }
      }

      .list-fund {
        margin-top: $space-6;
        display: grid;
        grid-template-columns: 1fr;
        gap: $space-4;

        @include responsive(tablet) {
          margin-top: $space-12;
          gap: $space-8;
          gap: 0;
          margin-top: 14rem;
          grid-template-columns: repeat(3, 1fr);
        }

        .item-fund {
          display: flex;
          align-items: center;
          flex-direction: column;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -7rem;
            height: 6rem;
            width: 1px;
            background-color: $brand;
            display: none;

            @include responsive(tablet) {
              display: block;
            }
          }

          // &:nth-child(2) {
          //   &::after {
          //     display: none;
          //   }
          // }

          .sub-text {
            font-size: 2.4rem;
            margin-bottom: $space-4;

            @include responsive(tablet) {
              font-size: 3.2rem;
            }
          }

          .text-content {
            font-size: 1.6rem;
            color: $text-secondary;

            @include responsive(tablet) {
              width: 70%;
              font-size: 1.8rem;
            }
          }

          .link-direction {
            display: flex;
            align-items: center;
            gap: $space-2;
            color: $brand;
            cursor: pointer;
            transition: 0.3s all;
            font-size: 1.6rem;
            margin-top: $space-4;
            display: none;

            &:hover {
              color: $brand-hover;
            }
          }
        }
      }
    }
  }
}
